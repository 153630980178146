<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.7388 16.4137C21.5716 17.6841 20.9477 18.8501 19.9837 19.6941C19.0196 20.5381 17.7813 21.0023 16.5 21C9.05626 21 3.00001 14.9437 3.00001 7.49999C2.99771 6.2187 3.4619 4.98039 4.30588 4.01633C5.14986 3.05228 6.31592 2.42841 7.58626 2.26124C7.9075 2.22202 8.2328 2.28774 8.51362 2.44859C8.79444 2.60944 9.0157 2.8568 9.14438 3.15374L11.1244 7.57406V7.58531C11.2229 7.81261 11.2636 8.06077 11.2428 8.30763C11.222 8.55449 11.1404 8.79236 11.0053 8.99999C10.9884 9.02531 10.9706 9.04874 10.9519 9.07218L9.00001 11.3859C9.7022 12.8128 11.1947 14.2922 12.6403 14.9962L14.9222 13.0547C14.9446 13.0358 14.9681 13.0183 14.9925 13.0022C15.2 12.8638 15.4387 12.7793 15.687 12.7564C15.9353 12.7335 16.1854 12.7729 16.4147 12.8709L16.4269 12.8766L20.8434 14.8556C21.1409 14.9838 21.3889 15.2049 21.5503 15.4858C21.7116 15.7666 21.7778 16.0922 21.7388 16.4137Z"
      fill="#333333"
    />
  </svg>
</template>

<script lang="ts" setup></script>
